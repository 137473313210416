import { ColorSchemeProvider, useColorScheme } from '@ichiql/react-color-scheme';
import type { LinksFunction } from '@remix-run/cloudflare';
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useRouteError } from
'@remix-run/react';

import styles from './index.css';

export const links: LinksFunction = () => [
{ rel: 'stylesheet', href: styles },
{ rel: 'preconnect', href: 'https://fonts.googleapis.com' },
{
  rel: 'preconnect',
  href: 'https://fonts.gstatic.com',
  crossOrigin: 'anonymous'
},
{
  rel: 'stylesheet',
  href: 'https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;700&display=swap'
}];


export default function AppWithProviders() {
  return (
    <ColorSchemeProvider>
      <App />
    </ColorSchemeProvider>);

}

function App() {
  const { colorScheme } = useColorScheme();

  return (
    <html
      lang='en'
      data-color-mode={colorScheme}>

      <head>
        <meta charSet='utf-8' />
        <meta
          name='viewport'
          content='width=device-width,initial-scale=1' />

        <Meta />
        <Links />
      </head>
      <body>
        <Outlet />
        <ScrollRestoration />
        <Scripts />
        <LiveReload port={8788} />
      </body>
    </html>);

}

export function ErrorBoundary() {
  const error = useRouteError();
  console.log('ERROR FROM BOUNDARY', error);
  return <div>Error</div>;
}